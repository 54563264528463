import { formatInTimeZone } from 'date-fns-tz';
import { Location, Session } from '../../stores/types';
import { convertToDate } from '../../utils/Date.Util';

export const getSortedRecentSessions = (
  sessions: Session[] | undefined,
  transactions: Session[] | undefined,
  chargers: any[],
  dateRange: Date[],
) => {
  if (!sessions || !transactions) return [];
  let result = sessions.concat(transactions);

  if (chargers && chargers?.length > 0) {
    const isAnyChargerSelected = chargers.some((charger) => charger.selected);
    if (isAnyChargerSelected) {
      result = result.filter((session) =>
        chargers.some(
          (charger) =>
            charger.id === session.port.charger.id && charger.selected,
        ),
      );
    }
  }

  if (dateRange?.length === 2) {
    result = result.filter((session) => {
      const startTime = session.startTime || session.createTime;
      const date = startTime && new Date(startTime);
      return date && date > dateRange[0] && date < dateRange[1];
    });
  }

  // sort
  result.sort((a, b) => {
    const startTimeA = a.startTime || a.createTime;
    const startTimeB = b.startTime || b.createTime;
    if (!startTimeA) return 1;
    if (!startTimeB) return -1;
    // BE uses a ISO/W3C date format on responsev & we convert it to UTC to compare
    const formatedStartTimeA = formatInTimeZone(
      startTimeA,
      'UTC',
      'yyyy-MM-dd HH:mm:ss zzz',
    );
    const formatedStartTimeB = formatInTimeZone(
      startTimeB,
      'UTC',
      'yyyy-MM-dd HH:mm:ss zzz',
    );
    return Date.parse(formatedStartTimeA) < Date.parse(formatedStartTimeB)
      ? 1
      : -1;
  });
  return result;
};

export const handleTimeCalculation = (
  timeInMinutes: number | string,
  roundTime = false,
) => {
  let resTime;
  const time = Number(timeInMinutes);
  if (time < 60) {
    resTime = `${timeInMinutes} min`;
  } else {
    const hours = time / 60;
    const minutes = time % 60;
    resTime = roundTime
      ? `${Math.trunc(hours)} hr`
      : `${Math.trunc(hours)} hr ${minutes} min`;
  }
  return resTime;
};

export const generateFullAddress = (location: Location) => {
  let fullAddress = '';
  if (location?.streetAddress) {
    fullAddress += location.streetAddress.concat(', ');
  }
  if (location?.city) {
    fullAddress += location.city.concat(', ');
  }
  if (location?.stateOrRegion) {
    fullAddress += location.stateOrRegion.concat(', ');
  }
  if (location?.isoCountryCode) {
    fullAddress += location.isoCountryCode.concat(', ');
  }
  if (location?.zipOrPostalCode) {
    fullAddress += location.zipOrPostalCode;
  }
  return fullAddress;
};

export const generateChargerStatusHistory = (
  status: any,
  startTime: any,
  endTime: any,
  t: any,
  timeZone: any,
) => {
  const chargerStatusHistory = [];
  if (status === 'FAILED') {
    chargerStatusHistory.push({
      timeZone,
      title: t('Failed'),
      status: 'failed',
      date: convertToDate(startTime),
    });
  } else if (status === 'PREPARING') {
    chargerStatusHistory.push(
      { title: t('preparing'), status: 'preparing' },
      {
        title: t('start'),
        status: 'start',
        date: convertToDate(startTime),
        timeZone,
      },
    );
  } else if (status === 'IN_PROGRESS') {
    chargerStatusHistory.push(
      { title: t('in_use'), status: 'in_use' },
      {
        title: t('start'),
        status: 'start',
        date: convertToDate(startTime),
        timeZone,
      },
    );
  } else if (status === 'SUSPENDED_EV') {
    chargerStatusHistory.push(
      { title: t('suspended_ev'), status: 'suspended_ev' },
      {
        title: t('start'),
        status: 'start',
        date: convertToDate(startTime),
        timeZone,
      },
    );
  } else if (status === 'SUSPENDED_EVSE') {
    chargerStatusHistory.push(
      { title: t('suspended_evse'), status: 'suspended_evse' },
      {
        title: t('start'),
        status: 'start',
        date: convertToDate(startTime),
        timeZone,
      },
    );
  } else {
    chargerStatusHistory.push(
      {
        title: t('completed'),
        status: 'completed',
        date: convertToDate(endTime),
        timeZone,
      },
      { title: t('in_use'), status: 'in_use' },
      {
        title: t('start'),
        status: 'start',
        date: convertToDate(startTime),
        timeZone,
      },
    );
  }
  return chargerStatusHistory;
};
