import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Calculator } from '../../../assets/icons';
import { useCompany } from '../../../hooks/useCompany';
import { useNavigateWithSearchParam } from '../../../hooks/useNavigateWithSearchParam';
import { payterContext } from '../../../hooks/usePayter';
import { RoutePath } from '../../../routes';
import { Charger } from '../../../stores/types';
import { PAYTER_VIEW } from '../../Payouts/PayoutTerminal/PayterTerminal.component';
import RenderLearnMoreText from '../../Payouts/PayoutTerminal/RenderLearnMoreText.component';
import { ColorType, Label, LabelType, Pill } from '../../_ui';
import EditTerminal from './EditTerminal.component';

interface IProps {
  charger: Charger;
  editEnabled: boolean;
  handleInputChange: (key: string, val: string) => void;
}

const ViewTerminal = ({ charger, editEnabled, handleInputChange }: IProps) => {
  const { t } = useTranslation();

  const payterConsumer = useContext(payterContext);
  const { isPayterConnected } = payterConsumer;

  const { companyId } = useCompany();

  const { navigate } = useNavigateWithSearchParam();

  const redirectPayoutPage = () => {
    navigate(
      `/${RoutePath.PAYOUT}?companyId=${companyId}&view=${PAYTER_VIEW.CONNECT_TERMINAL}`,
      {
        replace: true,
      },
    );
  };

  const renderTerminalNumber = () => {
    return charger.paymentTerminalSerialNumber ? (
      <Pill
        label={`${charger.paymentTerminalSerialNumber} (${t('payter')})`}
        iconLeft={Calculator}
      />
    ) : (
      <Label
        text={t('no_terminal_linked')}
        type={LabelType.BODY3}
        color={ColorType.BLACK}
      />
    );
  };

  const renderChargerTerminalView = () => {
    return editEnabled ? (
      <EditTerminal charger={charger} handleInputChange={handleInputChange} />
    ) : (
      <div className='my-2.5'>{renderTerminalNumber()}</div>
    );
  };

  const alignTerminalClass = isPayterConnected
    ? 'items-center'
    : 'items-start py-2.5';

  return (
    <div className='flex flex-col pt-4 pb-3'>
      <Label
        text={t('payment_terminal')}
        type={LabelType.LABEL_M}
        color={ColorType.GREY5}
        className='mb-2'
      />
      <div className='mb-2'>
        <RenderLearnMoreText text='allow_driver_charging_using_payment_terminal' />
      </div>
      <div className={`flex flex-row ${alignTerminalClass}`}>
        <Label
          text={t('terminal')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-3'
        />
        {isPayterConnected ? (
          renderChargerTerminalView()
        ) : (
          <div>
            <Label
              text={t('no_payter_account_connected')}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
            <Label
              text={t('goto_payout_to_connect_payter')}
              type={LabelType.BODY4}
              color={ColorType.BRAND2}
              onClick={redirectPayoutPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewTerminal;
