// React
import { useEffect, useMemo, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

// Hooks
import { useTranslation } from 'react-i18next';

// Utils
import { subMonths } from 'date-fns';
import { convertToLocaleCurrency } from '../../utils/Currency.Util';
import {
  convertDateToString,
  convertMilliSecondToDate,
  formatDate,
} from '../../utils/Date.Util';
import { getFormattedSimpleStats, getStatCurrencies } from './utils';

// API
import {
  useLazyFetchStatisticsCSVQuery,
  useLazyFetchStatisticsCSVV3Query,
  useLazyFetchStatisticsV3Query,
} from '../../services/history.api';

// Components
import {
  Button,
  Card,
  ColorType,
  DateSelector,
  Icon,
  Label,
  LabelType,
  Segment,
  VerticalBarChart,
} from '../_ui';
import { ButtonSize, ButtonType } from '../_ui/Button.component';

// CSS
import { displayEneryKwh } from '../../utils/Number.Util';
import './Data-Report.css';

// Constants
import { ChartNoData } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { useCompany } from '../../hooks/useCompany';
import { SimpleStatsRequest } from '../../stores/types/stats.interface';

export interface DataReportProps {
  locationId?: string | undefined;
  companyIds: (string | null)[] | null | undefined;
}

const today = new Date();
export const DataReport = ({ locationId, companyIds }: DataReportProps) => {
  const { t } = useTranslation();
  const { isMultiViewActive, userScope, companyId } = useCompany();
  const defaultSwitchOption = 'transactions';
  const [dataReportQuery, setDataReportQuery] = useQueryParams({
    barChartKey: StringParam,
    barChartStartRangeKey: StringParam,
    barChartEndRangeKey: StringParam,
  });

  // Refetch & get swtich option from url (if present) else default value (i.e. transaction) when url query changes
  const valueField = useMemo(() => {
    return dataReportQuery.barChartKey || defaultSwitchOption;
  }, [dataReportQuery]);

  // Refetch & get date rangefrom url (if present) else default value when url query changes
  const selectedRange = useMemo(() => {
    return dataReportQuery.barChartStartRangeKey &&
      dataReportQuery.barChartEndRangeKey
      ? [
          convertMilliSecondToDate(dataReportQuery.barChartStartRangeKey),
          convertMilliSecondToDate(dataReportQuery.barChartEndRangeKey),
        ]
      : [subMonths(today, 11), today];
  }, [dataReportQuery]);

  const [triggerFetchStasCSV] = useLazyFetchStatisticsCSVQuery(undefined);
  const [triggerFetchStasCSVV3] = useLazyFetchStatisticsCSVV3Query(undefined);
  const [selCurrency, setSelCurrency] = useState('CAD');
  const [
    triggerFetchStatistics,
    { hasData, stats, statsCurrencies, isStatsLoading, isStatsFetching },
  ] = useLazyFetchStatisticsV3Query({
    pollingInterval: 60000,
    selectFromResult: (endPoint) => ({
      hasData: endPoint.data
        ? endPoint.data.some(
            (stat: any) =>
              stat.transactions !== 0 ||
              stat.revenue !== 0 ||
              stat.energyDeliveredKWh !== 0,
          )
        : false,
      stats: getFormattedSimpleStats(
        endPoint.data,
        selectedRange,
        selCurrency,
        dataReportQuery.barChartKey || '',
      ),
      statsCurrencies: getStatCurrencies(endPoint.data),
      isStatsLoading: endPoint.isLoading,
      isStatsFetching: endPoint.isFetching,
    }),
  });

  const switchChanges = (checked: string) => {
    setSelCurrency('CAD');
    switch (checked) {
      case t('overview_fees_collected'): {
        setDataReportQuery({ barChartKey: 'revenue' });
        break;
      }
      case t('overview_energy_used'): {
        setDataReportQuery({ barChartKey: 'energyDeliveredKWh' });
        break;
      }
      case t('overview_sessions'): {
        setDataReportQuery({ barChartKey: 'transactions' });
        break;
      }
      default:
    }
  };

  const dateChanged = (selectedDate: any) => {
    setDataReportQuery({
      barChartStartRangeKey: convertDateToString(selectedDate[0]),
      barChartEndRangeKey: convertDateToString(selectedDate[1]),
    });
  };

  const handleButtonClick = () => {
    if (isMultiViewActive) {
      triggerFetchStasCSVV3({
        fromDate: formatDate(selectedRange[0], 'yyyy-MM'),
        toDate: formatDate(selectedRange[1], 'yyyy-MM'),
        scope: userScope,
        filter_in: {
          companyId: companyId !== 'ALL' ? [companyId!] : companyIds,
        },
      });
    } else {
      triggerFetchStasCSV({
        fromDate: formatDate(selectedRange[0], 'yyyy-MM'),
        toDate: formatDate(selectedRange[1], 'yyyy-MM'),
        scope: userScope,
      });
    }
  };

  const renderExportCSVButton = () => {
    return (
      <Button
        size={ButtonSize.SMALL}
        label={t('export_csv')}
        type={ButtonType.TERTIARY}
        onClick={handleButtonClick}
        disabled={stats?.length === 0}
        dataTestId='export-stats-data-button'
      />
    );
  };

  const getFormattedText = (val: any, digitsNumber = 0) => {
    switch (valueField) {
      case 'revenue': {
        return convertToLocaleCurrency(val, undefined, digitsNumber);
      }
      case 'energyDeliveredKWh': {
        return displayEneryKwh(val, t('kwh'));
      }
      default:
        return Number(val).toLocaleString('en-US');
    }
  };

  // added dash for separate currency symbol into tooltip
  const onTooltipTitle = (value: any) => {
    return `${getFormattedText(value, 2)}${
      dataReportQuery.barChartKey === 'revenue' ? `-${selCurrency}` : ''
    }`;
  };

  const onTickLabel = (tickValue: any) =>
    `${getFormattedText(tickValue)} ${
      dataReportQuery.barChartKey === 'revenue' ? selCurrency : ''
    }`;

  const renderVerticalBarChart = () => {
    if (!stats) return null;
    if (hasData) {
      return (
        <VerticalBarChart
          items={stats}
          className='flex ml-auto mr-auto mt-0 mb-0 w-auto max-w-[1152px]'
          dateField='date'
          valueField={valueField}
          onTickLabel={onTickLabel}
          onTooltipTitle={onTooltipTitle}
        />
      );
    }
    return (
      <div className='flex flex-col justify-center h-52'>
        <div className='flex justify-center'>
          <Icon
            className='grey5'
            src={ChartNoData}
            size={IconSize.SIZE_32x32}
          />
        </div>
        <div className='flex justify-center my-2'>
          <Label
            text={t('vertical_bar_chart_no_data')}
            type={LabelType.H4}
            color={ColorType.BLACK}
          />
        </div>
        <div className='flex justify-center mt-2'>
          <Label
            text={t('vertical_bar_chart_no_data_desc')}
            type={LabelType.BODY3}
            color={ColorType.GREY5}
            className='text-base'
          />
        </div>
      </div>
    );
  };

  // Helper function to translate url key value back to actual value
  const matchOptionValues = (name: string) => {
    switch (name) {
      case 'revenue': {
        return t('overview_fees_collected');
      }
      case 'energyDeliveredKWh': {
        return t('overview_energy_used');
      }
      case 'transactions': {
        return t('overview_sessions');
      }
      default:
        return name;
    }
  };

  const getCurrenciesSelector = useMemo(() => {
    return (
      <div className='flex flex-row gap-2 ml-4'>
        {statsCurrencies.map((currency: string) => (
          <Label
            text={currency}
            type={LabelType.LABEL_S}
            color={selCurrency === currency ? ColorType.BLACK : ColorType.GREY5}
            onClick={() => {
              setSelCurrency(currency);
            }}
            className={
              selCurrency === currency ? 'border-b-2 border-black' : ''
            }
          />
        ))}
      </div>
    );
  }, [statsCurrencies]);

  useEffect(() => {
    const requestObject: any = {
      fromDate: formatDate(selectedRange[0], 'yyyy-MM'),
      toDate: formatDate(selectedRange[1], 'yyyy-MM'),
      aggregateLocations: isMultiViewActive ? undefined : !locationId,
      scope: userScope,
      filter_in: {
        companyId: companyId !== 'ALL' ? [companyId!] : companyIds,
      },
      locationId: isMultiViewActive ? undefined : locationId,
    } as SimpleStatsRequest;

    triggerFetchStatistics(requestObject);
  }, [
    dataReportQuery,
    selectedRange,
    companyId,
    isMultiViewActive,
    locationId,
    triggerFetchStatistics,
    userScope,
    companyIds,
  ]);

  return (
    <Card>
      <div className='flex w-full'>
        <div className='flex w-3/5 items-center'>
          <Segment
            className='whitespace-nowrap'
            options={[
              t('overview_sessions'),
              t('overview_energy_used'),
              t('overview_fees_collected'),
            ]}
            onChange={switchChanges}
            segmentedDefault={matchOptionValues(valueField)}
          />
          {dataReportQuery.barChartKey === 'revenue' && getCurrenciesSelector}
        </div>
        <div className='flex justify-end w-3/5 gap-8 '>
          <DateSelector
            onChange={dateChanged}
            endDate={selectedRange[1]}
            isLoading={isStatsLoading && isStatsFetching}
          />
          {renderExportCSVButton()}
        </div>
      </div>
      {renderVerticalBarChart()}
    </Card>
  );
};
