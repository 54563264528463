import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import { payterContext } from '../../../hooks/usePayter';
import {
  Button,
  ButtonType,
  Card,
  MODAL_TYPES,
  Skeleton,
  useGlobalModalContext,
} from '../../_ui';
import ConnectPayterAccount from './ConnectPayterAccount';
import TerminalHeader from './TerminalHeader.component';
import TerminalTable from './TerminalTable.component';

export enum PAYTER_VIEW {
  CONNECT_TERMINAL = 'connect-account',
}
const PayterTerminal = () => {
  const { t } = useTranslation();

  const payterConsumer = useContext(payterContext);
  const { isPayterConnected, company, isCompanyLoading } = payterConsumer;
  const { showModal, hideModal } = useGlobalModalContext();

  const [view, setView] = useQueryParam('view', StringParam);

  const onCloseModel = () => {
    hideModal();
    if (view) {
      setView(undefined);
    }
  };

  const handlePayterAccount = () => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('connect_payter_account'),
      overflow: 'auto',
      width: '540px',
      height: 'fit-content',
      onRenderBody: () => <ConnectPayterAccount companyDetails={company} />,
      onIconClick: onCloseModel,
      shouldCloseOnOverlayClick: false,
      headerClass: 'pt-5 mb-2',
    });
  };

  useEffect(() => {
    if (view === PAYTER_VIEW.CONNECT_TERMINAL) {
      handlePayterAccount();
    }
  }, [view]);

  return (
    <Card className='p-5 h-fit'>
      <TerminalHeader />
      {isPayterConnected ? (
        <TerminalTable />
      ) : isCompanyLoading ? (
        <Skeleton height='h-[40px]' width='w-[196px]' />
      ) : (
        <Button
          label={t('connect_payter_account')}
          type={ButtonType.SECONDARY}
          isLoading={isCompanyLoading}
          onClick={handlePayterAccount}
          dataTestId='connect-payter-button'
        />
      )}
    </Card>
  );
};

export default PayterTerminal;
