import { formatInTimeZone } from 'date-fns-tz';
import { memo } from 'react';
import { ColorType, Label, LabelType } from '..';
import { getLocale } from '../../../utils/Date.Util';
import { TimelineData } from './types/Timeline.interface';

interface InputProps {
  data: TimelineData[];
  renderTitle?: Function;
  renderContent?: Function;
}

const renderItem = (
  data: TimelineData[],
  renderTitle?: Function,
  renderContent?: Function,
) =>
  data.map((row: TimelineData, index) => (
    <div
      key={`${row.title}-${row?.date?.getTime()}`}
      className='flex items-center relative'
    >
      {index !== data.length - 1 && (
        <div className='border-r-2 border-black absolute h-full left-1 top-2 mb-10' />
      )}
      <div className='w-5 h-5 rounded-full bg-white top-0.5 -left-1 absolute' />
      <div className='w-2.5 h-2.5 rounded-full bg-black top-2 absolute' />

      <div className='ml-10 flex flex-col mb-5'>
        <div className='mb-2'>
          {renderTitle ? (
            renderTitle(row.title, row.status)
          ) : (
            <Label
              text={row.title}
              type={LabelType.H3}
              color={ColorType.BLACK}
            />
          )}
        </div>

        {renderContent ? (
          renderContent(row.date, index, row.timeZone)
        ) : (
          <Label
            text={
              row.date
                ? formatInTimeZone(
                    row.date,
                    row.timeZone || '',
                    'LLL dd, h:mm a',
                    getLocale(),
                  )
                : ''
            }
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
          />
        )}
      </div>
    </div>
  ));

export const Timeline = memo(
  ({ renderTitle, renderContent, data }: InputProps) => (
    <div>{renderItem(data, renderTitle, renderContent)}</div>
  ),
);
