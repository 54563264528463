import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  autoUpdate,
  FloatingFocusManager,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { subMonths } from 'date-fns';
import { useSelector } from 'react-redux';
import {
  CheckBoxData,
  ColorType,
  DateRangePicker,
  getHexColorByType,
  Label,
  LabelType,
} from '../index';

import {
  formatDateRange,
  getLastFifteenMin,
  getLastMonth,
  getLastOneDay,
  getLastOneHrs,
  getLastWeek,
} from '../../../utils/Date.Util';

import { Calendar, ChevronRight } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { getCurrentTheme } from '../../../stores/selectors/theme.selector';
import { Icon } from '../Icon.component';

export enum DATE_RANGE {
  LAST_FIFTEEN_MIN = 'LAST_FIFTEEN_MIN',
  LAST_ONE_HOUR = 'LAST_ONE_HOUR',
  LAST_ONE_DAY = 'LAST_ONE_DAY',
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  CUSTOM_RANGE = 'CUSTOM_RANGE',
}

export interface InputProps {
  onChange?: (option: string, date: any) => void;
  format?: string;
  optionList: Array<DATE_RANGE>;
  selectedOption: string;
  selectedDate?: Date[];
  showHighligtHeader?: boolean;
  placeholder?: string;
  dataTestId?: string;
  includeDays?: number;
}

const datePickerdefaultOptions = [
  {
    label: 'date_picker_last_fifteen_minutes',
    selected: false,
    id: DATE_RANGE.LAST_FIFTEEN_MIN,
  },
  {
    label: 'date_picker_last_hour',
    selected: false,
    id: DATE_RANGE.LAST_ONE_HOUR,
  },
  {
    label: 'date_picker_last_day',
    selected: false,
    id: DATE_RANGE.LAST_ONE_DAY,
  },
  {
    label: 'date_picker_last_week',
    selected: false,
    id: DATE_RANGE.LAST_WEEK,
  },
  {
    label: 'date_picker_last_month',
    selected: false,
    id: DATE_RANGE.LAST_MONTH,
  },
  {
    label: 'date_picker_custom_range',
    selected: false,
    id: DATE_RANGE.CUSTOM_RANGE,
  },
];

export const CustomDatePicker = memo(
  ({
    onChange,
    format,
    optionList,
    selectedOption = DATE_RANGE.LAST_MONTH,
    selectedDate,
    showHighligtHeader = true,
    placeholder,
    dataTestId = '',
    includeDays,
  }: InputProps) => {
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const [isPickerOpen, setIsPickerOpen] = useState(false);

    const selectedDefaultOptions = useMemo(() => {
      return datePickerdefaultOptions?.map((item) => {
        if (item.id === selectedOption) {
          return { ...item, selected: true };
        }
        return item;
      });
    }, [selectedOption]);

    const defaultOptions = selectedDefaultOptions?.filter((item) =>
      optionList?.includes(item.id),
    );

    const { t } = useTranslation();

    const [selectedRange, setSelectedRange] = useState<(Date | null)[]>([
      subMonths(new Date(), 1),
      null,
    ]);

    // Updates selected range when direct url with search params is opened
    useEffect(() => {
      if (
        selectedDate?.length === 2 &&
        selectedDate?.[0] &&
        selectedDate?.[1]
      ) {
        setSelectedRange(selectedDate);
      }
    }, [selectedDate]);

    const [options, setOptions] = useState<CheckBoxData[]>(defaultOptions);

    // Updates selected range when direct url with search params is opened
    useEffect(() => {
      setOptions(
        defaultOptions.map((option) => {
          return {
            ...option,
            selected: selectedOption === option.id,
          };
        }),
      );
    }, [selectedOption]);

    const toggleMenu = (val: boolean) => {
      setIsDropDownOpen(val);
    };

    const openPicker = () => {
      setIsPickerOpen(true);
      setIsDropDownOpen(false);
    };

    const closePicker = () => {
      setIsPickerOpen(false);
    };

    useEffect(() => {
      if (selectedRange?.length === 2 && selectedRange[0] && selectedRange[1]) {
        onChange &&
          onChange(
            options.filter(
              (option: CheckBoxData) => option.selected === true,
            )[0]?.id || DATE_RANGE.LAST_MONTH,
            selectedRange,
          );
        closePicker();
      }
    }, [selectedRange]);

    const customDateChanged = (date: Date[]) => {
      setSelectedRange(date);
    };

    const optionChanged = (val: CheckBoxData) => {
      const newOptions = options.map((option) => {
        return {
          ...option,
          selected: option.id === val.id,
        };
      });
      setOptions([...newOptions]);

      switch (val?.id) {
        case DATE_RANGE.LAST_FIFTEEN_MIN: {
          setSelectedRange(getLastFifteenMin());
          break;
        }

        case DATE_RANGE.LAST_ONE_HOUR: {
          setSelectedRange(getLastOneHrs());
          break;
        }

        case DATE_RANGE.LAST_ONE_DAY: {
          setSelectedRange(getLastOneDay());
          break;
        }

        case DATE_RANGE.LAST_WEEK: {
          setSelectedRange(getLastWeek());
          break;
        }
        case DATE_RANGE.LAST_MONTH: {
          setSelectedRange(getLastMonth());
          break;
        }
        case DATE_RANGE.CUSTOM_RANGE: {
          setSelectedRange([null, null]);
          openPicker();
          break;
        }

        default:
      }

      setIsDropDownOpen(false);
    };

    const getHeaderText = () => {
      const selectedId = options.find((o) => o.selected)?.id;
      switch (selectedId) {
        case DATE_RANGE.LAST_FIFTEEN_MIN:
          return t('date_picker_last_fifteen_min');
        case DATE_RANGE.LAST_ONE_HOUR:
          return t('date_picker_last_hour');
        case DATE_RANGE.LAST_ONE_DAY:
          return t('date_picker_last_day');
        case DATE_RANGE.LAST_WEEK:
          return t('date_picker_last_week');
        case DATE_RANGE.LAST_MONTH:
          return t('date_picker_last_month');
        case DATE_RANGE.CUSTOM_RANGE:
          return selectedRange?.length > 0
            ? formatDateRange(selectedRange)
            : t('date_picker_custom_range');
        default:
          console.warn('unknown range id');
          return 'Select time range';
      }
    };

    const onPickerClicked = () => {
      const newOptions = options.map((option) => {
        return {
          ...option,
          selected: option.id === DATE_RANGE.CUSTOM_RANGE,
        };
      });
      setOptions(newOptions);
      openPicker();
    };

    const renderCustomRanngeButton = () => {
      const selectedOptionId = options.find((o) => o.selected)?.id;
      if (selectedOptionId !== DATE_RANGE.CUSTOM_RANGE) {
        return null;
      }
      return (
        <button
          className='flex flex-row items-center justify-between text-left py-[10px] px-3 w-full h-[40px] bg-grey1 outline-0 cursor-pointer'
          onClick={onPickerClicked}
        >
          <Label
            text={formatDateRange(selectedRange)}
            type={LabelType.BODY4}
            color={ColorType.BLACK}
          />
          <Icon src={ChevronRight} size={IconSize.SIZE_20x20} />
        </button>
      );
    };

    const theme = useSelector(getCurrentTheme);

    const renderDropdown = () => {
      return (
        <>
          {options.map((option) => {
            return (
              <div
                className='w-full py-2 pl-2 cursor-pointer hover:bg-grey1 outline-0'
                onClick={() => optionChanged(option)}
                style={{
                  backgroundColor:
                    option.selected && option.id !== DATE_RANGE.CUSTOM_RANGE
                      ? getHexColorByType(ColorType.GREY1)
                      : '',
                }}
              >
                <Label
                  className='outline-0'
                  type={LabelType.BODY3}
                  color={ColorType.BLACK}
                  text={t(option.label)}
                  style={{
                    color:
                      option.selected && option.id !== DATE_RANGE.CUSTOM_RANGE
                        ? theme.brand_2
                        : getHexColorByType(ColorType.BLACK),
                  }}
                />
              </div>
            );
          })}
          {renderCustomRanngeButton()}
        </>
      );
    };

    const handleClickDateRangeOutSide = () => {
      if (isPickerOpen) {
        setIsPickerOpen(false);
        // means only start range selected, set default option and set select range to last month
        if (selectedRange[1] === null) {
          setOptions(defaultOptions);
          setSelectedRange(getLastMonth());
        }
      }
    };

    const renderDateRangePicker = () => {
      if (!isPickerOpen) {
        return null;
      }
      return (
        <div className='absolute py-2 z-50'>
          <DateRangePicker
            defaultRange={selectedRange}
            onChange={customDateChanged}
            format={format}
            monthsShown={2}
            inline
            onClickOutSide={handleClickDateRangeOutSide}
            placeholder={placeholder}
            includeDays={includeDays}
          />
        </div>
      );
    };

    const { refs, floatingStyles, context } = useFloating({
      placement: 'bottom-start',
      open: isDropDownOpen,
      onOpenChange: (val) => {
        toggleMenu(val);
      },
      middleware: [
        offset(10),
        size({
          apply({ rects, elements, availableHeight }) {
            Object.assign(elements.floating.style, {
              maxHeight: `${availableHeight}px`,
              minWidth: `${rects.reference.width}px`,
            });
          },
          padding: 10,
        }),
      ],
      whileElementsMounted: autoUpdate,
    });

    const click = useClick(context);
    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([
      click,
      dismiss,
    ]);

    const renderHeaderInput = () => {
      const selectedOptionId = options.find((o) => o.selected)?.id;
      const isHighLight =
        showHighligtHeader &&
        (selectedOptionId === DATE_RANGE.CUSTOM_RANGE ||
          selectedOptionId === DATE_RANGE.LAST_WEEK ||
          selectedOptionId === DATE_RANGE.LAST_FIFTEEN_MIN ||
          selectedOptionId === DATE_RANGE.LAST_ONE_HOUR ||
          selectedOptionId === DATE_RANGE.LAST_ONE_DAY);

      return (
        <div
          ref={refs.setReference}
          {...getReferenceProps()}
          className={`flex h-10 rounded  items-center cursor-pointer ${
            isHighLight ? 'bg-grey6' : 'bg-grey1 hover:bg-grey2 '
          }`}
          data-testid={dataTestId}
        >
          <Icon
            src={Calendar}
            className='ml-4'
            size={IconSize.SIZE_20x20}
            color={isHighLight ? ColorType.WHITE : ''}
          />
          <Label
            text={getHeaderText()}
            type={LabelType.LABEL_S_MEDIUM}
            color={isHighLight ? ColorType.WHITE : ColorType.GREY6}
            className='ml-2.5 pr-4'
          />
        </div>
      );
    };

    return (
      <>
        <div>
          {renderHeaderInput()}
          <div className='relative min-w-fit'>{renderDateRangePicker()}</div>
        </div>
        {isDropDownOpen && (
          <FloatingFocusManager context={context} modal={false}>
            <div
              className='z-50 outline-0 p-2 list-shadow rounded bg-white w-[200px]'
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              {renderDropdown()}
            </div>
          </FloatingFocusManager>
        )}
      </>
    );
  },
);
