import { Personalization } from '../stores/types/personalization.interface';
import { API_KEYS, Api } from './base.api';

export const personalizationApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchPersonalization: builder.query<Personalization, void>({
      query: () => ({
        url: `/core/v1/users/me/preferences/${API_KEYS.PERSONALIZATION}`,
        method: 'GET',
        needCompanyId: false,
      }),
    }),
  }),
});

export const {
  useFetchPersonalizationQuery,
  useLazyFetchPersonalizationQuery,
} = personalizationApi;
