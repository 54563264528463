import { carIcon } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { ColorType, Icon, Label, LabelType } from '../../_ui';

interface Props {
  activePort: number;
  totalPort: number;
}

export const Car = ({ activePort, totalPort }: Props) => {
  return (
    <div className='flex flex-row gap-x-1 items-center'>
      <Icon src={carIcon} size={IconSize.SIZE_20x20} />
      <Label
        text={`${activePort} / ${totalPort}`}
        type={LabelType.LABEL_XS}
        color={ColorType.GREY5}
        className='pt-1'
      />
    </div>
  );
};
