import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Company } from '../../stores/types';
// Components
import {
  EmailOutline,
  ErrorWarningCircle,
  ExternalLink,
} from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { USER_ROLE, useAuth } from '../../hooks';
import { useCompany } from '../../hooks/useCompany';
import { useSendBankInfoReminderMutation } from '../../services/company.api';
import {
  BankInfoProvided,
  sendBankInfoReminderPayload,
} from '../../stores/types/company.interface';
import {
  Button,
  ButtonSize,
  ButtonType,
  Card,
  ColorType,
  Icon,
  Label,
  LabelType,
} from '../_ui';
import {
  MODAL_TYPES,
  useGlobalModalContext,
} from '../_ui/modal/GlobalModal.component';
import { CompanyEditor } from './CompanyEditor.component';

interface CompanyInfoProp {
  company: Company | undefined;
  isCompanyLoading: boolean;
  // Determines if any user is present within company
  isUserPresent: boolean;
}

export const CompanyInfo = ({
  company,
  isCompanyLoading = false,
  isUserPresent = false,
}: CompanyInfoProp) => {
  const { companies: companylist } = useCompany();
  const [sendReminder, sendReminderQueryResult] =
    useSendBankInfoReminderMutation();

  /* Finding the channel Partner name from the companylist array. */
  const ChannelPartnerName = companylist?.find(
    (ele) => ele?.id === company?.businessInfo?.channelPartnerId,
  );
  const auth = useAuth();

  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const handleEditClick = () => {
    if (!company) {
      return;
    }
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('company_edit_detail'),
      width: '540px',
      height: 'max-content',
      onRenderBody: () => <CompanyEditor company={company} />,
      shouldCloseOnOverlayClick: false,
    });
  };

  const address = useMemo(() => {
    let result = '';
    if (company?.street) {
      const unit = company.unit ? `${company.unit}-` : '';
      result = `${unit}${company.streetNumber} ${company?.street}, ${company?.city}, ${company?.stateOrRegion}, ${company?.isoCountryCode}`;
    }
    return result;
  }, [company]);

  const onExternalLinkClick = () => {
    window.open(
      `https://chargelab.co/chargelab-forms/banking-information?dashboard_company_id=${company?.id}`,
    );
  };
  /**
   * Handles the click event on the send reminder.
   * This function triggers the `sendBankInfoReminder` api, which sends a reminder
   * to all the users of company regarding their missing bank information.
   */
  const onClickSendReminder = () => {
    sendReminder({
      companyId: company?.id,
      companyName: company?.name,
    } as sendBankInfoReminderPayload);
  };

  /**
   * Determines the disabled state of the 'Send Reminder' icon & label based on three conditions.
   * 1. Checks if a user is not present in company.
   * 2. Assesses if the send reminder query operation was successful
   * 3. Confirms that the company has provided bank information
   * The button is disabled if any of these conditions are true,
   * indicating either a lack of user, a successful reminder operation, or the presence of bank information.
   *
   * @returns {boolean} true if the send reminder should be disabled, false otherwise.
   */
  const disableSendReminderBtn = () => {
    return (
      !isUserPresent ||
      sendReminderQueryResult.isSuccess ||
      company?.bankInfoProvided !== BankInfoProvided.MISSING
    );
  };

  const handleExternalLinkDiv = () => {
    if (
      isCompanyLoading ||
      company?.bankInfoProvided === BankInfoProvided.NOT_REQUIRED
    ) {
      return null;
    }
    return (
      <div className='flex flex-row items-center gap-1'>
        <Icon
          src={ExternalLink}
          size={IconSize.SIZE_16x16}
          color={ColorType.BRAND2}
          onClick={onExternalLinkClick}
        />
        <Label
          text={
            company?.bankInfoProvided === BankInfoProvided.PROVIDED
              ? t('banking_info_update_info_link')
              : t('banking_info_provide_info_link')
          }
          type={LabelType.LABEL_S_MEDIUM}
          color={ColorType.BRAND2}
          onClick={onExternalLinkClick}
        />
      </div>
    );
  };

  const handleSendReminderDiv = () => {
    if (
      isCompanyLoading ||
      auth.role !== USER_ROLE.SUPPORT ||
      company?.bankInfoProvided !== BankInfoProvided.MISSING
    ) {
      return null;
    }
    return (
      <div className='flex flex-row items-center gap-1 pl-4'>
        <Icon
          src={EmailOutline}
          size={IconSize.SIZE_16x16}
          color={disableSendReminderBtn() ? ColorType.GREY3 : ColorType.BRAND2}
          onClick={disableSendReminderBtn() ? undefined : onClickSendReminder}
        />
        <Label
          text={
            !isUserPresent
              ? t('banking_info_reminder')
              : disableSendReminderBtn()
              ? t('banking_info_sent_reminder')
              : t('banking_info_reminder')
          }
          type={LabelType.LABEL_S_MEDIUM}
          color={disableSendReminderBtn() ? ColorType.GREY3 : ColorType.BRAND2}
          onClick={disableSendReminderBtn() ? undefined : onClickSendReminder}
        />
      </div>
    );
  };

  /**
   * Determines the label for the bank information based on the company's bank info status.
   * The function checks the status of bank information provided by the company and returns
   * either MISSING, NOT REQUIRED or PROVIDED.
   * If the status is not one of the above, an empty string is returned.
   *
   * @returns {string} The internationalized label for the company's bank information status.
   */
  const handleBankInfoLabel = () => {
    switch (company?.bankInfoProvided) {
      case BankInfoProvided.MISSING:
        return t('banking_info_not_present');
      case BankInfoProvided.NOT_REQUIRED:
        return t('banking_info_not_required');
      case BankInfoProvided.PROVIDED:
        return t('banking_info_present');
      default:
        return '';
    }
  };

  if (!company) return null;

  return (
    <Card className='border-2 border-grey2'>
      <div className='flex flex-row items-start justify-between'>
        <div className='flex flex-row gap-10'>
          <div className='flex flex-col gap-[8px]'>
            <div className='flex flex-row gap-[40px] items-baseline'>
              <Label
                className='min-w-[154px] max-w-[88px]'
                text={t('company_name')}
                type={LabelType.LABEL_M}
                color={ColorType.GREY6}
              />
              <Label
                text={company?.name}
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                isLoading={isCompanyLoading}
                skeletonHeight='h-5'
              />
            </div>
            <div className='flex flex-row gap-[40px] items-baseline min-w-[50%]'>
              <Label
                className='min-w-[154px] max-w-[88px]'
                text={t('company_address')}
                type={LabelType.LABEL_M}
                color={ColorType.GREY6}
              />
              <Label
                text={address}
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                isLoading={isCompanyLoading}
                skeletonHeight='h-5'
              />
            </div>
            {auth.role === USER_ROLE.SUPPORT && (
              <>
                <div className='flex flex-row gap-[40px] items-baseline'>
                  <Label
                    className='min-w-[154px] max-w-[154px]'
                    text={t('company_service_fee')}
                    type={LabelType.LABEL_M}
                    color={ColorType.GREY6}
                  />
                  <Label
                    text={`${
                      company?.serviceFeePercentage !== undefined
                        ? `${company?.serviceFeePercentage}%`
                        : ''
                    }`}
                    type={LabelType.BODY3}
                    color={ColorType.BLACK}
                    isLoading={isCompanyLoading}
                    skeletonHeight='h-5'
                  />
                </div>
                <div className='flex flex-row gap-[40px] items-baseline'>
                  <Label
                    className='min-w-[154px] max-w-[88px]'
                    text={t('company_ela_status')}
                    type={LabelType.LABEL_M}
                    color={ColorType.GREY6}
                  />
                  <Label
                    text={company?.businessInfo?.ela ? t('yes') : t('no')}
                    type={LabelType.BODY3}
                    color={ColorType.BLACK}
                    isLoading={isCompanyLoading}
                    skeletonHeight='h-5'
                  />
                </div>
              </>
            )}
            <div className='flex flex-row gap-[40px] items-baseline'>
              <Label
                className='min-w-[154px] max-w-[156px]'
                text={t('banking_info')}
                type={LabelType.LABEL_M}
                color={ColorType.GREY6}
              />
              <div className='flex flex-col gap-1'>
                <div className='flex flex-row gap-2 items-center'>
                  {company?.bankInfoProvided === BankInfoProvided.MISSING &&
                    !isCompanyLoading && (
                      <Icon
                        src={ErrorWarningCircle}
                        size={IconSize.SIZE_16x16}
                      />
                    )}
                  <Label
                    text={handleBankInfoLabel()}
                    type={
                      company?.bankInfoProvided !== BankInfoProvided.MISSING
                        ? LabelType.BODY3
                        : LabelType.LABEL_S_MEDIUM
                    }
                    color={
                      company?.bankInfoProvided !== BankInfoProvided.MISSING
                        ? ColorType.BLACK
                        : ColorType.NEGATIVE1
                    }
                    isLoading={isCompanyLoading}
                    skeletonHeight='h-5'
                  />
                  <div
                    className={`${
                      auth.role === USER_ROLE.SUPPORT
                        ? 'hidden xl:flex flex-row pl-2'
                        : 'flex flex-row pl-2'
                    }`}
                  >
                    {handleExternalLinkDiv()}
                    {handleSendReminderDiv()}
                  </div>
                </div>
                <div
                  className={`${
                    auth.role === USER_ROLE.SUPPORT
                      ? 'flex flex-row xl:hidden'
                      : 'hidden'
                  }`}
                >
                  {handleExternalLinkDiv()}
                  {handleSendReminderDiv()}
                </div>
                {company?.bankInfoProvided === BankInfoProvided.MISSING && (
                  <Label
                    text={t('banking_info_message')}
                    type={LabelType.LABEL_XS_MEDIUM}
                    color={ColorType.GREY5}
                    isLoading={isCompanyLoading}
                    skeletonHeight='h-5'
                  />
                )}
              </div>
            </div>
          </div>
          <div className='flex flex-col flex-grow gap-[8px]'>
            <div className='flex flex-row gap-[8px] items-baseline'>
              <Label
                className='w-[110px]'
                text={t('company_phone')}
                type={LabelType.LABEL_M}
                color={ColorType.GREY6}
              />
              <Label
                text={company?.contactPhone}
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                isLoading={isCompanyLoading}
                skeletonHeight='h-5'
              />
            </div>
            <div className='flex flex-row gap-[8px] items-baseline'>
              <Label
                className='w-[110px]'
                text={t('company_email')}
                type={LabelType.LABEL_M}
                color={ColorType.GREY6}
              />
              <Label
                text={company?.contactEmail}
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                isLoading={isCompanyLoading}
                skeletonHeight='h-5'
              />
            </div>
            {auth.role === USER_ROLE.SUPPORT && (
              <>
                <div className='flex flex-row gap-[8px] items-baseline'>
                  <Label
                    className='w-[110px]'
                    text={t('company_network')}
                    type={LabelType.LABEL_M}
                    color={ColorType.GREY6}
                  />
                  <Label
                    text={company.whiteLabellingId || ''}
                    type={LabelType.BODY3}
                    color={ColorType.BLACK}
                    isLoading={isCompanyLoading}
                    skeletonHeight='h-5'
                  />
                </div>
                <div className='flex flex-row gap-[8px] items-baseline'>
                  <Label
                    className='w-[110px]'
                    text={t('company_partner')}
                    type={LabelType.LABEL_M}
                    color={ColorType.GREY6}
                  />
                  <Label
                    text={ChannelPartnerName?.name || t('none')}
                    type={LabelType.BODY3}
                    color={ColorType.BLACK}
                    isLoading={isCompanyLoading}
                    skeletonHeight='h-5'
                  />
                </div>
                <div className='flex flex-row gap-[8px] items-baseline'>
                  <Label
                    className='w-[110px]'
                    text={t('company_test_company')}
                    type={LabelType.LABEL_M}
                    color={ColorType.GREY6}
                  />
                  <Label
                    text={
                      company?.businessInfo?.testCompany ? t('yes') : t('no')
                    }
                    type={LabelType.BODY3}
                    color={ColorType.BLACK}
                    isLoading={isCompanyLoading}
                    skeletonHeight='h-5'
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className='flex flex-row w-[148px] items-center justify-end'>
          <Button
            label={t('company_edit_detail')}
            onClick={handleEditClick}
            type={ButtonType.TERTIARY}
            size={ButtonSize.SMALL}
            dataTestId='editCompanyButton'
          />
        </div>
      </div>
    </Card>
  );
};
