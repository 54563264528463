/* eslint-disable react/react-in-jsx-scope */
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ColorType,
  Dropdown,
  FormInput,
  GroupDirection,
  Icon,
  Label,
  LabelType,
  RadioGroup,
  Switch,
} from '../../_ui';

import { Info } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { InputType } from '../../_ui/Input.component';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../_ui/Tooltip.component';
import { transformChargerType } from '../utils';

interface Props {
  loadData: any;
  activeIndex: number;
  dropdownOptions: any;
  handleInputChange: (key: string, value: any) => void;
  isLoading: boolean;
  isEdit: boolean;
}

export const ManagedEVCharger = ({
  loadData,
  activeIndex,
  dropdownOptions,
  handleInputChange,
  isLoading,
  isEdit,
}: Props) => {
  const { t } = useTranslation();
  const {
    deviceId,
    model,
    type,
    minAmp,
    maxAmperage,
    overrideZeroAmperage,
    maxAmpsOverrideFactoryDefault,
    maxAmpsOverride,
    zeroAmpSupport,
    supportZeroAmpsOverrideFactoryDefault,
  } = loadData[activeIndex];

  const yesNoOptions = useMemo(() => {
    return [
      {
        id: 'true',
        label: t('yes'),
        selected: overrideZeroAmperage === true,
      },
      {
        id: 'false',
        label: t('no'),
        selected: overrideZeroAmperage === false,
      },
    ];
  }, [overrideZeroAmperage, activeIndex]);

  const maxAmpsOverrideValidation = (fixedAmperage: any) => {
    return _.isNumber(Number(fixedAmperage)) && fixedAmperage > 0;
  };

  const renderChargerSkeleton = () => {
    return (
      <div>
        {Array(5)
          .fill(0)
          .map((item) => (
            <div className='flex flex-col pb-4 gap-2'>
              <Label
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
                isLoading
                skeletonWidth='w-2/4'
                skeletonHeight='h-5'
              />
              <Label
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
                isLoading
                skeletonWidth='w-52'
                skeletonHeight='h-5'
              />
            </div>
          ))}
      </div>
    );
  };

  const getWarningLabel = useMemo(() => {
    if (maxAmpsOverrideFactoryDefault) {
      return (
        <div className='flex flex-row gap-2 p-2 border-2 rounded-lg border-grey3'>
          <Icon src={Info} size={IconSize.SIZE_24x24} />
          <Label
            text={t('charger_custom_limit_label')}
            type={LabelType.BODY4}
            color={ColorType.GREY6}
          />
        </div>
      );
    }
    return null;
  }, [maxAmpsOverrideFactoryDefault]);

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col pb-4 gap-2'>
        <Label
          type={LabelType.LABEL_M}
          color={ColorType.BLACK}
          text={t('charger')}
        />
        <Dropdown
          placement='top-start'
          items={dropdownOptions}
          onItemClick={(selectedItem: any) => {
            handleInputChange('deviceId', selectedItem.id);
          }}
          headerWidth='100%'
          placeholder={t('pm_select_charger')}
          placeholderLabelType={LabelType.BODY3_G4}
          labelType={LabelType.BODY3_G4}
          labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
          chevdownIconHighlightColor={ColorType.GREY6}
          contentDivHeight={205}
          headerHighLightClassName='bg-grey1 border-grey3 rounded'
          dataTestId='selCharger'
        />
      </div>
      {(isLoading || (!model && !!deviceId)) && renderChargerSkeleton()}
      {model && !isLoading && !!deviceId && (
        <>
          <div className='flex flex-col pb-4 gap-2'>
            <Label
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
              text={t('load_modal_field_2')}
            />
            <Label
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              text={model}
            />
          </div>
          <div className='flex flex-col pb-4 gap-2'>
            <Label
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
              text={t('load_modal_field_3')}
            />
            <Label
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              text={transformChargerType(type)}
            />
          </div>
          <div className='flex flex-col pb-4 gap-2'>
            <Label
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
              text={t('load_modal_field_4')}
            />
            <div className='flex flex-row gap-6'>
              {maxAmpsOverrideFactoryDefault ? (
                <FormInput
                  showErrorIcon
                  defaultValue={maxAmpsOverride}
                  placeholder='0'
                  inputType={InputType.NUMBER}
                  onChange={(event: any) => {
                    handleInputChange('maxAmpsOverride', event.target.value);
                  }}
                  props={{ style: { width: '58px' } }}
                  onValidation={maxAmpsOverrideValidation}
                  wrapperClassName='inline-block'
                />
              ) : (
                <Label
                  type={LabelType.BODY3}
                  color={ColorType.BLACK}
                  text={`${Math.ceil(maxAmperage || 0)} amps`}
                />
              )}
              <Switch
                value={maxAmpsOverrideFactoryDefault}
                enableLabel={t('pm_override_factory_default')}
                disableLabel={t('pm_override_factory_default')}
                labelColor={ColorType.GREY6}
                onChange={(event: any) => {
                  handleInputChange('maxAmpsOverrideFactoryDefault', event);
                }}
              />
            </div>
            {maxAmpsOverrideFactoryDefault && getWarningLabel}
          </div>
          <div className='flex flex-col pb-4 gap-2'>
            <div className='flex flex-row gap-1'>
              <Label
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
                text={t('load_modal_field_5')}
              />
              <Tooltip placement='top-start'>
                <TooltipTrigger>
                  <Icon src={Info} size={IconSize.SIZE_24x24} />
                </TooltipTrigger>
                <TooltipContent>
                  <Label
                    text={t('pm_min_amperage_tooltip')}
                    type={LabelType.BODY3}
                    color={ColorType.WHITE}
                  />
                </TooltipContent>
              </Tooltip>
            </div>
            <Label
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              text={`${Math.ceil(minAmp || 0)} amps`}
            />
          </div>
          <div className='flex flex-col pb-4 gap-2'>
            <div className='flex flex-row gap-1'>
              <Label
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
                text={t('load_modal_field_6')}
              />
              <Tooltip placement='top-start'>
                <TooltipTrigger>
                  <Icon src={Info} size={IconSize.SIZE_24x24} />
                </TooltipTrigger>
                <TooltipContent>
                  <Label
                    text={t('pm_zero_amperage_tooltip')}
                    type={LabelType.BODY3}
                    color={ColorType.WHITE}
                  />
                </TooltipContent>
              </Tooltip>
            </div>
            <div className='flex flex-row gap-6'>
              {supportZeroAmpsOverrideFactoryDefault ? (
                <RadioGroup
                  direction={GroupDirection.Horizontal}
                  defaultItems={yesNoOptions}
                  onChange={(items: any) => {
                    const selOption = items.filter(
                      (item: any) => item.selected,
                    );
                    handleInputChange(
                      'overrideZeroAmperage',
                      JSON.parse(selOption[0].id),
                    );
                  }}
                  itemHeight='20px'
                />
              ) : (
                <Label
                  type={LabelType.BODY3}
                  color={ColorType.BLACK}
                  text={zeroAmpSupport ? t('yes') : t('no')}
                />
              )}
              <Switch
                value={supportZeroAmpsOverrideFactoryDefault}
                enableLabel={t('pm_override_factory_default')}
                disableLabel={t('pm_override_factory_default')}
                labelColor={ColorType.GREY6}
                onChange={(event: any) => {
                  handleInputChange(
                    'supportZeroAmpsOverrideFactoryDefault',
                    event,
                  );
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
