/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/display-name */
import _ from 'lodash';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  FloatingFocusManager,
  Placement,
  autoUpdate,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import {
  CheckBoxData,
  CheckBoxGroup,
  CheckBoxTree,
  ColorType,
  FormInput,
  GroupDirection,
  Icon,
  Label,
  LabelType,
  Pill,
  Scrollable,
} from '..';
import { CancelClose, ChevronDown, Search } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { TEXT } from '../../../constant/Text.constant';
import { getCurrentTheme } from '../../../stores/selectors/theme.selector';
import { getHighlightedTextColor } from '../../Charger/utils';

export enum DropdownType {
  SELECT,
  CHECKBOX,
  CHECKBOX_TREE,
}

type Item = {
  id: string | number;
} & Record<string, any>;

interface DropdownProps {
  searchDataTestId?: string;
  placeholder?: string;
  placeholderLabelType?: LabelType;
  items?: Array<any>;
  onItemClick?: Function;
  handleAllSelect?: Function;
  type?: DropdownType;
  headerWidth?: any;
  label?: string;
  headerClassName?: string;
  headerHighLightClassName?: string;
  labelType?: LabelType;
  labelTypeHighlighted?: LabelType;
  chevdownIcon?: string;
  chevdownIconHighlightColor?: string;
  contentDivWidth?: number;
  contentDivHeight?: number;
  headerHeight?: string;
  translationOn?: boolean;
  disabled?: boolean;
  checkboxTreeParentPadding?: string;
  checkboxTreeChildPadding?: string;
  checkboxTreeRowClassName?: string;
  showPillHeader?: boolean;
  pillIcon?: string;
  pillIsButton?: boolean;
  showFooter?: boolean;
  renderFooter?: any;
  placement?: Placement;
  dataTestId?: string;
}

export const Dropdown = memo(
  ({
    searchDataTestId = '',
    placeholder,
    placeholderLabelType = LabelType.LABEL_S_GREY4,
    items = [],
    onItemClick,
    handleAllSelect,
    type = DropdownType.SELECT,
    headerWidth = 143,
    label = 'label',
    headerClassName = 'hover:bg-grey2  bg-grey1 border-grey3 rounded',
    headerHighLightClassName = 'bg-grey6 hover:bg-grey5 border-grey3 rounded',
    labelType = LabelType.DROPDOWN_HEADER,
    labelTypeHighlighted = LabelType.DROPDOWN_HEADER_SELECTED,
    chevdownIcon = ChevronDown,
    chevdownIconHighlightColor = ColorType.WHITE,
    contentDivWidth,
    contentDivHeight = 300,
    headerHeight = '40px',
    translationOn = false,
    disabled = false,
    checkboxTreeParentPadding,
    checkboxTreeChildPadding,
    checkboxTreeRowClassName = '',
    showPillHeader = false,
    pillIcon = '',
    pillIsButton = true,
    showFooter = undefined,
    renderFooter,
    placement = 'bottom-start',
    dataTestId = '',
  }: DropdownProps) => {
    const className = 'bg-grey1';

    const showSearchBar = useMemo(() => {
      let res = items.length > 5;
      items.forEach((element) => {
        if (element.children?.length > 5) {
          res = true;
        }
      });
      return res;
    }, [items]);

    const { t } = useTranslation();

    const theme = useSelector(getCurrentTheme);

    const checkAndTranslate = (stringToBeTranslated: any) => {
      if (translationOn) {
        return t(stringToBeTranslated);
      }
      return stringToBeTranslated;
    };

    const [_items, setItems] = useState<Item[]>([...items]);
    const [isOpen, setOpen] = useState(false);
    const [searchStr, setSearchStr] = useState<string>('');
    const [filteredItems, setFilteredItems] = useState<Item[]>([...items]);

    const handleSearch = (searchText: string, list: Item[]) => {
      const filteredList = list.filter((item) => {
        return item[label]?.toLowerCase().includes(searchText?.toLowerCase());
      });
      setFilteredItems(_.cloneDeep(filteredList));
      setSearchStr(searchText);
    };

    const onOpenChange = (val: boolean) => {
      if (val === false) {
        handleSearch('', _items);
      }
      setOpen(val);
    };

    const handleItemClick = (item: any, index: number) => {
      const newItems: Array<any> = _items.map((_item, _index) => ({
        ..._item,
        selected: _item.id === item.id,
      }));
      setItems(newItems);
      onItemClick && onItemClick(item, index);
      onOpenChange(false);
    };

    const removeClass = (el: any) => {
      if (el.classList) {
        if (type !== DropdownType.SELECT) {
          el.parentNode.parentNode.classList.remove(className);
        } else {
          el.classList?.remove(className);
        }
      }
    };

    const addClass = (el: any) => {
      if (el.classList) {
        if (type !== DropdownType.SELECT) {
          el.parentNode.parentNode.classList.add(className);
        } else {
          el.classList?.add(className);
        }
      }
    };

    useEffect(() => {
      let selectedDropdownItem: Element | null = null;
      let selectedDropdownItemIndex = -1;

      const handleKeyDown = (event: { key: string }) => {
        const dropdownListButtonEle = document.querySelectorAll(
          '#dropdown-list button',
        );
        const dropdownListLength = dropdownListButtonEle.length;

        if (event.key === 'ArrowDown') {
          selectedDropdownItemIndex =
            (selectedDropdownItemIndex + 1) % dropdownListLength;
        } else if (event.key === 'ArrowUp') {
          selectedDropdownItemIndex =
            (selectedDropdownItemIndex - 1 + dropdownListLength) %
            dropdownListLength;
        } else if (event.key === 'Enter') {
          if (type === DropdownType.SELECT) {
            handleItemClick(
              filteredItems[selectedDropdownItemIndex],
              selectedDropdownItemIndex,
            );
          } else {
            dropdownListButtonEle[selectedDropdownItemIndex].dispatchEvent(
              new MouseEvent('click', { bubbles: true }),
            );
          }
        }

        if (selectedDropdownItem) {
          removeClass(selectedDropdownItem);
        }

        selectedDropdownItem = dropdownListButtonEle[selectedDropdownItemIndex];

        if (selectedDropdownItem) {
          addClass(selectedDropdownItem);
          selectedDropdownItem.scrollIntoView({ block: 'nearest' });
        }
      };

      if (isOpen) {
        document
          .getElementById('dropdown-search-input')
          ?.focus({ preventScroll: true });
        document.addEventListener('keydown', handleKeyDown);
      } else {
        if (selectedDropdownItem) {
          removeClass(selectedDropdownItem);
          selectedDropdownItem = null;
          selectedDropdownItemIndex = -1;
        }
        document.removeEventListener('keydown', handleKeyDown);
      }

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [isOpen, filteredItems]);

    useEffect(() => {
      setItems(_.cloneDeep(items));
      // Passing searchStr as CHECKBOX & CHECKBOX_TREE allows multi select
      handleSearch(searchStr || '', items);
    }, [items]);

    const selectedIcon = useMemo(() => {
      let res = null;
      _items.forEach((item) => {
        if (item.selected) {
          res = item.icon;
        }
      });
      return res;
    }, [_items]);

    const selectedLabel = useMemo(() => {
      let res = null;
      if (type === DropdownType.SELECT) {
        _items.forEach((item) => {
          if (item.selected) {
            res = item[label];
          }
        });
      }

      return res;
    }, [_items, label]);

    const handleHeaderClick = () => {
      // clear search string before hide the dropdown window
      if (isOpen) {
        handleSearch('', _items);
      }
      setOpen(!isOpen);
    };

    const handleClearClick = () => {
      let newItems;
      if (type === DropdownType.CHECKBOX_TREE) {
        newItems = _items.map((_item, _index) => ({
          ..._item,
          selected: false,
          children: _item.children?.map((child: any) => ({
            ...child,
            selected: false,
          })),
        }));
      } else {
        newItems = _items.map((_item, _index) => ({
          ..._item,
          selected: false,
        }));
      }
      handleSearch('', _items);
      setItems(newItems);
      onItemClick && onItemClick(newItems);
    };

    const handleCheckBoxSelect = (newItems: CheckBoxData[]) => {
      setItems([...newItems]);
      onItemClick && onItemClick(newItems);
    };

    const handleParentPillClick = (item: any, pIndex: number) => {
      const newItems = _items.map((_item, index) => {
        if (index === pIndex || item.id === TEXT.ALL) {
          const newChildren = _item.children?.map((child: any) => ({
            ...child,
            selected: false,
          }));
          return { ..._item, selected: false, children: newChildren };
        }
        return _item;
      });
      setItems(newItems);
      onItemClick && onItemClick(newItems);
    };

    const handleChildPillClick = (pIndex: number, cIndex: number) => {
      const newItems = _items.map((_item, index) => {
        if (index === pIndex) {
          let isAllChildrenChecked = true;
          const newChildren = _item.children?.map(
            (child: any, childIndex: number) => {
              const newSelected =
                childIndex === cIndex ? false : child.selected;
              if (!newSelected) isAllChildrenChecked = false;
              return {
                ...child,
                selected: newSelected,
              };
            },
          );
          return {
            ..._item,
            selected: isAllChildrenChecked,
            children: newChildren,
          };
        }
        return _item;
      });
      setItems(newItems);
      onItemClick && onItemClick(newItems);
    };

    const getPillComponents = () => {
      const pills: any = [];
      const getPill = (item: any, pIndex: number) => {
        return (
          <Pill
            // eslint-disable-next-line react/no-array-index-key
            key={`${item[label]}-${pIndex}`}
            onClick={() => handleParentPillClick(item, pIndex)}
            label={item[label]}
            hasCloseButton={pillIsButton}
            translationOn={translationOn}
            disabled={disabled}
            iconLeft={pillIcon}
          />
        );
      };

      if (_items[0].id === TEXT.ALL && _items[0].selected) {
        pills.push(getPill(_items[0], 0));
      } else {
        _items.forEach((item, pIndex) => {
          if (item.selected) {
            pills.push(getPill(item, pIndex));
          } else {
            item.children?.forEach((child: any, cIndex: number) => {
              if (child.selected) {
                pills.push(
                  <Pill
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${item[label]}-${cIndex}`}
                    onClick={() => handleChildPillClick(pIndex, cIndex)}
                    label={child.label}
                    hasCloseButton={pillIsButton}
                    translationOn={translationOn}
                    disabled={disabled}
                    iconLeft={pillIcon}
                  />,
                );
              }
            });
          }
        });
      }
      return pills;
    };

    const isAnyItemSelected = useMemo(() => {
      if (type === DropdownType.SELECT || type === DropdownType.CHECKBOX) {
        return _items.some((_item) => _item.selected);
      }
      if (type === DropdownType.CHECKBOX_TREE) {
        return _items.some((_item) => {
          return (
            _item.selected ||
            _item.children?.some((child: any) => child.selected)
          );
        });
      }
    }, [_items]);

    const renderPillsHeader = () => {
      const pills = getPillComponents();
      if (pills.length > 0) {
        return (
          <div
            className='bg-grey1 flex flex-row hover:bg-grey2 place-content-between px-2 py-2.5 max-h-32 rounded-lg hover:cursor-pointer'
            style={{ width: headerWidth }}
            onClick={handleHeaderClick}
            data-testid={dataTestId}
          >
            <div className='flex flex-wrap gap-1 overflow-x-hidden overflow-y-auto'>
              {pills}
            </div>

            <button
              className='justify-self-end flex-none'
              type='button'
              disabled={disabled}
            >
              <Icon className='ml-4' src={ChevronDown} />
            </button>
          </div>
        );
      }
      return <div />;
    };

    const renderHeader = () => {
      return (
        <button
          className={`w-full pl-[12px] pr-2 py-2.5
            ${isAnyItemSelected ? headerHighLightClassName : headerClassName}
            `}
          onClick={handleHeaderClick}
          style={{ width: headerWidth, height: headerHeight }}
          disabled={disabled}
          data-testid={dataTestId}
        >
          <div className='w-full h-full place-content-between flex flex-row justify-between items-center'>
            {selectedLabel ? (
              <Label
                text={checkAndTranslate(selectedLabel)}
                type={isAnyItemSelected ? labelTypeHighlighted : labelType}
                icon={selectedIcon}
                className='truncate text-left'
                color={disabled ? ColorType.GREY3 : ''}
              />
            ) : (
              <Label
                text={checkAndTranslate(placeholder)}
                type={
                  isAnyItemSelected
                    ? labelTypeHighlighted
                    : placeholderLabelType
                }
                icon={selectedIcon}
                className='truncate text-left'
                /** For the quick fixes getting text color from label type.
                 * We need to refactor this code on dropdown improvement
                 * Issue - Label default color (black) override label type color
                 * */
                color={
                  isAnyItemSelected
                    ? getHighlightedTextColor(labelTypeHighlighted)
                    : getHighlightedTextColor(placeholderLabelType)
                }
              />
            )}
            <Icon
              className='ml-4'
              src={chevdownIcon}
              color={
                disabled
                  ? ColorType.GREY3
                  : isAnyItemSelected
                  ? chevdownIconHighlightColor
                  : ''
              }
            />
          </div>
        </button>
      );
    };

    const renderSearchBar = () => (
      <div className='sticky top-0 z-10 bg-white'>
        <FormInput
          width='100%'
          placeholder={t('search')}
          icon={Search}
          iconSize={IconSize.SIZE_20x20}
          onChange={(event: any) => handleSearch(event.target.value, _items)}
          showOutline={false}
          suffixIcon={searchStr ? CancelClose : ''}
          sufficIconSize={IconSize.SIZE_20x20}
          defaultValue={searchStr}
          onClickSuffix={() => {
            handleSearch('', _items);
          }}
          inputId='dropdown-search-input'
          dataTestId={searchDataTestId || 'searchInput'}
        />
      </div>
    );

    const renderContentFooter = () => {
      if (renderFooter) {
        return <div onClick={() => onOpenChange(false)}>{renderFooter}</div>;
      }
      return (
        <div
          className='hover:bg-grey1 hover:cursor-pointer pl-2 pr-[12px] text-left flex items-center min-h-[40px] mb-2'
          onClick={handleClearClick}
        >
          <Label
            type={LabelType.DROPDOWN_ITEM_SELECTED}
            text={t('clear')}
            style={{ color: theme.navigationSelectedColor }}
          />
        </div>
      );
    };
    const renderItems = () => {
      switch (type) {
        case DropdownType.SELECT:
          return filteredItems.map((item, index) => {
            const key = `${item[label]}-${index}`;
            return (
              <button
                key={key}
                type='button'
                className={` hover:bg-grey1 pl-2 pr-2 min-h-[40px] text-left w-full ${
                  item.selected && 'bg-grey1 rounded'
                }`}
                onClick={() => handleItemClick(item, index)}
                data-testid='optionItem'
                disabled={item.selectedIds?.includes(item.id)}
              >
                <div className='flex flex-row justify-between items-center'>
                  <Label
                    type={
                      item.selected
                        ? LabelType.DROPDOWN_ITEM_SELECTED
                        : item.selectedIds?.includes(item.id)
                        ? LabelType.BODY3_DISABLED
                        : LabelType.BODY3
                    }
                    text={checkAndTranslate(item[label])}
                    icon={item.icon}
                    className='truncate'
                    style={{
                      color: item.selected
                        ? theme.navigationSelectedColor
                        : item.selectedIds?.includes(item.id)
                        ? ColorType.GREY3
                        : 'black',
                    }}
                  />
                  {item?.secondLabel && (
                    <Label
                      text={item.secondLabel}
                      type={LabelType.LABEL_XS}
                      color={ColorType.GREY4}
                    />
                  )}
                  {item.secondLabelContent && item.secondLabelContent()}
                </div>
              </button>
            );
          });

        case DropdownType.CHECKBOX:
          return (
            <CheckBoxGroup
              defaultItems={items}
              onChange={handleCheckBoxSelect}
              handleAllSelect={handleAllSelect}
              direction={GroupDirection.Vertical}
              filterStr={searchStr}
              label={label}
              translationOn={translationOn}
              hoverEffect
              dataTestId='optionItem'
            />
          );
        case DropdownType.CHECKBOX_TREE:
          return (
            <CheckBoxTree
              defaultNodes={_items as CheckBoxData[]}
              onChange={handleCheckBoxSelect}
              filterStr={searchStr}
              translationOn={translationOn}
              width={headerWidth}
              parentPadding={checkboxTreeParentPadding}
              childPadding={checkboxTreeChildPadding}
              rowClassName={checkboxTreeRowClassName}
              dataTestId='optionItem'
            />
          );
        default:
          return null;
      }
    };

    const renderContent = () => {
      if (!isOpen) {
        return null;
      }
      let style;
      if (contentDivHeight) {
        style = { maxHeight: contentDivHeight };
      }
      if (contentDivWidth) {
        style = { ...style, width: contentDivWidth };
      }
      return (
        <div
          id='dropdown-list'
          className='flex flex-col bg-white'
          style={style}
        >
          {showSearchBar && renderSearchBar()}
          <div className='relative flex flex-col overflow-hidden-y'>
            {renderItems()}
          </div>
        </div>
      );
    };

    const { refs, floatingStyles, context } = useFloating({
      placement,
      open: isOpen,
      onOpenChange: (val) => {
        onOpenChange(val);
      },
      middleware: [
        offset(10),
        size({
          apply({ rects, elements, availableHeight }) {
            Object.assign(elements.floating.style, {
              maxHeight: `${availableHeight}px`,
              minWidth: `${rects.reference.width}px`,
              maxWidth: contentDivWidth,
            });
          },
          padding: 10,
        }),
      ],
      whileElementsMounted: autoUpdate,
    });

    const click = useClick(context);
    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([
      click,
      dismiss,
    ]);

    return (
      <>
        <div ref={refs.setReference} {...getReferenceProps()}>
          {showPillHeader && isAnyItemSelected
            ? renderPillsHeader()
            : renderHeader()}
        </div>
        {isOpen && (
          <FloatingFocusManager context={context} modal={false}>
            <div
              className='z-50 flex flex-col px-2 pt-2 bg-white
               outline-0 rounded-lg list-shadow hover:border-0' // px-2 pt-2 is a temp solution for using <scrollbar>, it will automated create a 2 px padding bottm
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
              data-testid='optionWrapper'
            >
              <Scrollable
                style={{
                  maxHeight: contentDivHeight,
                  // maxHeight: contentDivHeight - searchBarHeight,
                  // backgroundColor: 'red',
                }}
              >
                {renderContent()}
              </Scrollable>
              {showFooter && renderContentFooter()}
            </div>
          </FloatingFocusManager>
        )}
      </>
    );
  },
);
